import * as R from 'ramda'
import DescriptionIcon from '@material-ui/icons/Description'
import React from 'react'

import {alphabeticalSort} from 'helpers/utils'
import {financialPostsPropTypes} from 'helpers/propTypes'
import Date from 'components/UI/Date'
import Filter from 'components/UI/Filter'
import FiltersContainer from 'components/UI/FiltersContainer'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import useFinancialPagesFilter from 'hooks/useFinancialPagesFilter'
import useHashConverter from 'hooks/useHashConverter'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const FinancialPosts = ({
  currentDocuments,
  byTypeLabel,
  downloadCta,
  searchLabel,
  pageContext,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)
  const documentsIDs = R.map(e => e.contentful_id, currentDocuments)
  const {nodeLocale} = pageContext

  const typeTags = alphabeticalSort(
    Array.from(
      new Set(
        R.map(
          ({documentType}) => R.pathOr('', ['name'], documentType),
          currentDocuments,
        ),
      ),
    ),
  )

  const [selectedTags] = useHashConverter()

  const itemsToRender = useFinancialPagesFilter(
    nodeLocale,
    selectedTags,
    documentsIDs,
  )

  const renderPosts = () =>
    mapIndexed(
      document => (
        <>
          <div className={classes.postsContainer}>
            <div className={classes.textContainer}>
              <div className={classes.surtitle}>
                <DescriptionIcon />
                <p>{document.documentType.name}</p>
              </div>
              <p>{document.name}</p>

              <Date
                timeCode={document.publicationDate}
                node_locale={nodeLocale}
              />
            </div>
            <RoundButton
              color="secondary"
              downloadArrow
              isSmallText
              fileName={document.file.fileName}
              href={document.file.url}
              isCentered={isMobile}
              isDownload
              isAction
            >
              {downloadCta}
            </RoundButton>
          </div>
          <div className={classes.line} />
        </>
      ),
      itemsToRender,
    )

  return (
    <Section hasPaddingTop={false}>
      <div className={classes.container}>
        <div className={classes.articleContainer}>{renderPosts()}</div>
        <div className={classes.filters}>
          <FiltersContainer searchPlaceholder={searchLabel}>
            <Filter
              array={typeTags}
              filterLabel={byTypeLabel}
              currentTag={selectedTags}
            />
          </FiltersContainer>
        </div>
      </div>
    </Section>
  )
}

FinancialPosts.propTypes = financialPostsPropTypes

FinancialPosts.defaultProps = {
  byTypeLabel: '',
  currentDocuments: [],
  downloadCta: '',
  pageContext: {},
  searchLabel: '',
}

export default FinancialPosts
