import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, spacing, breakpoints}) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '60vh',
    [breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  articleContainer: {
    minWidth: '75%',
    [breakpoints.down('sm')]: {
      order: 2,
    },
    [breakpoints.up('md')]: {
      paddingRight: spacing(5),
      maxWidth: 600,
    },
  },
  filters: {
    minWidth: 250,
    [breakpoints.down('sm')]: {
      order: 1,
      marginBottom: spacing(4),
    },
  },
  postsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      margin: spacing(4, 'auto'),
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  textContainer: {
    '& p': {
      margin: spacing(1, 0),
    },
  },
  surtitle: {
    display: 'flex',
    alignItems: 'center',
    color: palette.primary.main,
    maxHeight: spacing(4),
    '& p': {
      paddingLeft: spacing(1),
      paddingTop: spacing(0.7),
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  line: {
    position: 'relative',
    width: '100%',
    height: 1,
    margin: spacing(4, 0),
    backgroundColor: palette.background.gray,
  },
}))

export default useStyles
